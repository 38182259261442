.fc {
  --fc-button-bg-color: #673ab7;
  --fc-button-border-color: #673ab7;
  --fc-button-active-bg-color: var(--fc-button-bg-color);
  --fc-button-active-border-color: var(--fc-button-bg-color);
  --fc-button-hover-bg-color: var(--fc-button-bg-color);
  --fc-button-hover-border-color: var(--fc-button-bg-color);

  --fc-event-bg-color: var(--fc-button-bg-color);
  --fc-event-border-color: var(--fc-button-bg-color);

  background-color: white;
}

@media (max-width: 900px) {
  .fc {
    height: 100%;
  }
}

.fc .fc-toolbar-title {
  font-weight: var(--font-weight-semibold);
  font-size: 1.25em;
}

.fc .fc-toolbar.fc-header-toolbar {
  border: 1px solid var(--fc-border-color);
  border-bottom: none;

  margin: 0;
  padding: 12px 16px;
}

.fc .fc-toolbar > * > :not(:first-child) {
  margin-left: 8px;
}

.fc .fc-today-button.fc-button-primary {
  background-color: transparent;
  text-transform: uppercase;
  color: var(--fc-button-border-color);
  letter-spacing: 1.25px;
}

.fc .fc-today-button.fc-button-primary:hover {
  background-color: rgba(103, 58, 183, 0.04);
  cursor: pointer;
}

.fc .fc-toolbar-title {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
}

.fc .fc-button-primary {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
}

.fc .fc-button-primary:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.fc .fc-button-primary:not(:disabled):active:focus {
  box-shadow: none;
}

.fc .fc-button-primary:hover,
.fc .fc-button-primary:not(:disabled).fc-button-active:hover {
  background-color: rgb(72, 40, 128);
}

.fc .fc-button-primary:not(:disabled):active:hover {
  opacity: 0.8;
}

.fc-daygrid-dot-event.fc-event-mirror,
.fc-daygrid-dot-event:hover {
  background-color: rgba(103, 58, 183, 0.1);
}

.fc .event-status-canceled,
.fc .event-status-canceled:hover {
  text-decoration: line-through;
  opacity: 0.4;
}

.fc .fc-event {
  cursor: pointer;
}

.fc .fc-button-group {
  gap: 8px;
}

.fc .fc-button-group > .fc-button:not(:first-child) {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.fc .fc-button-group > .fc-button:not(:last-child) {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
