:root {
  --font-weight-semibold: 500;
}

body {
  margin: 0;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  min-height: 100vh;
}

.maya-tabs {
  overflow-x: auto !important;
  -webkit-overflow-scrolling: touch;
}

.maya-tabs::-webkit-scrollbar {
  display: none;
}
